import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* appOptionsWorker({ type, payload }) {
  yield put({ type: types.SET_APP_OPTIONS, payload });
}

function* appThemeWorker({ type, payload }) {
  yield put({ type: types.SET_APP_THEME, payload });
}

function* appLocaleWorker({ type, payload }) {
  yield put({ type: types.SET_APP_LOCALE, payload });
}

export function* optionsWatcher() {
  yield takeEvery(types.WATCH_APP_OPTIONS, appOptionsWorker);
  yield takeEvery(types.WATCH_APP_THEME, appThemeWorker);
  yield takeEvery(types.WATCH_APP_LOCALE, appLocaleWorker);
}
