import { put, takeEvery } from 'redux-saga/effects';
import { axiosApiWithAuth } from '../../utils/axiosApi';
import types from '../types';

function* tokenWorker({ type, payload }) {
  yield put({ type: types.SET_AUTH_DETAIL, payload });
}

function* userWorker({ type, payload }) {
  yield put({ type: types.SET_USER, payload });
}

function* getProfileDetail({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.get('/mod/profile');

    yield put({
      type: types.SET_PROFILE_DETAIL,
      payload: { isSavingComplete: false, isSaving: false },
    });

    const { data, success } = res.data;

    if (success) {
      const profileDetail = {
        fullName: data.fullName,
        email: data.email,
        roleId: data.roleId,
        role: data.role,
        walletAddress: data.address,
      };
      yield put({ type: types.SET_PROFILE_DETAIL, payload: profileDetail });
    }
  } catch (e) {
    console.error('Error @ Fetch profile detail: ', e);
  }
}

function* updateProfile({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.patch('/mod/profile', payload);
    yield put({ type: types.SET_PROFILE_DETAIL, payload: { isSaving: true } });

    const { data, success } = res.data;

    if (success) {
      yield put({
        type: types.SET_PROFILE_DETAIL,
        payload: { isSavingComplete: true },
      });
    }
  } catch (e) {
    console.error('Error @ Fetch profile detail: ', e);
  }
}

export function* authWatcher() {
  yield takeEvery(types.WATCH_AUTH_DETAIL, tokenWorker);
  yield takeEvery(types.WATCH_USER, userWorker);
  yield takeEvery(types.WATCH_PROFILE_DETAIL, getProfileDetail);
  yield takeEvery(types.WATCH_UPDATE_PROFILE, updateProfile);
}
