import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* rolesDataWorker({ type, payload }) {
  yield put({ type: types.SET_ROLES_DATA, payload });
}

function* rolesFilterWorker({ type, payload }) {
  yield put({ type: types.SET_ROLES_FILTER, payload });
}

export function* rolesWatcher() {
  yield takeEvery(types.WATCH_ROLES_DATA, rolesDataWorker);
  yield takeEvery(types.WATCH_ROLES_FILTER, rolesFilterWorker);
}
