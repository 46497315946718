import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* poolsDataWorker({ type, payload }) {
  yield put({ type: types.SET_POOLS_DATA, payload });
}

function* poolsFilterWorker({ type, payload }) {
  yield put({ type: types.SET_POOLS_FILTER, payload });
}

export function* poolsWatcher() {
  yield takeEvery(types.WATCH_POOLS_DATA, poolsDataWorker);
  yield takeEvery(types.WATCH_POOLS_FILTER, poolsFilterWorker);
}
