import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../config';

const axiosApiNoAuth = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const axiosApiWithAuth = axios.create({
  baseURL: API_URL,
  headers: {
    ...axiosApiNoAuth.headers,
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export const axiosWithAuth = {
  ...axiosApiNoAuth,
  headers: {
    ...axiosApiNoAuth.headers,
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
};
export const axiosApiUnauth = axios.create(axiosApiNoAuth);

export const axiosApiAuth = axios.create(axiosApiWithAuth);
export const axiosApiAuthV2 = axios.create(axiosWithAuth);

const applyTokenAccess = config => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

axiosApiWithAuth.interceptors.request.use(applyTokenAccess, error =>
  Promise.reject(error),
);
