import { gql } from '@apollo/client';

const CORE_NFT_PRICE_FIELDS = gql`
  fragment CoreNFTPriceFields on NftPrice {
    amount
    currency
    dollEquivalent
  }
`;

const CORE_NFT_DETAILS_FIELDS = gql`
  fragment CoreNFTDetailsFields on NftDetails {
    contractAddress
    tokenId
    tokenStandard
    blockchain
  }
`;

const CORE_NFT_OWNER_FIELDS = gql`
  fragment CoreNFTOwnerFields on NftOwner {
    id
    key_id
  }
`;

const CORE_NFT_HISTORY_FIELDS = gql`
  ${CORE_NFT_PRICE_FIELDS}
  ${CORE_NFT_OWNER_FIELDS}
  fragment CoreNFTHistoryFields on NFTHistory {
    id
    event
    nftPrice {
      ...CoreNFTPriceFields
    }
    fromNftOwner {
      ...CoreNFTOwnerFields
    }
    toNftOwner {
      ...CoreNFTOwnerFields
    }
    date
  }
`;

export const CORE_CURRENCY_CONVERTER_FIELDS = gql`
  fragment CoreCurrencyConverterFields on ResponseTypeString {
    response
  }
`;

export const CORE_NFT_ITEM_FIELDS = gql`
  ${CORE_NFT_OWNER_FIELDS}
  ${CORE_NFT_HISTORY_FIELDS}
  fragment CoreNFTItemFields on NftItem {
    id
    key_id
    price
    token
    uri
    nftOwner {
      ...CoreNFTOwnerFields
    }
    status
    chain
    histories {
      ...CoreNFTHistoryFields
    }
  }
`;

export const CORE_NFT_FIELDS = gql`
  ${CORE_NFT_OWNER_FIELDS}
  ${CORE_NFT_HISTORY_FIELDS}
  ${CORE_NFT_DETAILS_FIELDS}
  fragment CoreNFTFields on Nft {
    id
    key_id
    price
    token
    uri
    nftOwner {
      ...CoreNFTOwnerFields
    }
    nftCreator
    status
    chain
    histories {
      ...CoreNFTHistoryFields
    }
    adminCanTransfer
    bridgeEnabled
    network
    file
    artwork
    artist
    social
    brief
    real_price
    royalties
    datetime
    bridgeUse
    details {
      ...CoreNFTDetailsFields
    }
    history {
      event
      to
      date
      price
      hash
    }
    viewDetails {
      viewAll
    }
    adminOwnerFee
    adminCreatorFee
    adminPlatformFee
  }
`;

export const CORE_NFT_LIST_RESULT_FIELDS = gql`
  ${CORE_NFT_FIELDS}
  fragment CoreNFTListFields on NFTList {
    count
    current {
      ...CoreNFTFields
    }
  }
`;
