import { gql } from '@apollo/client';

// Define mutation
export const ADMIN_SIGNIN = gql`
  mutation signInByEmail(
    $email: String!
    $password: String!
    $withRefresh: Boolean
  ) {
    signInByEmail(
      email: $email
      password: $password
      withRefresh: $withRefresh
    ) {
      accessToken
      refreshToken
    }
  }
`;

export const QUERY_ME = gql`
  query me {
    me {
      id: state
      roleId: type
      isVerified: onboardingCompletedAt
      deactivatedAt
      lastLoginAt
      createdAt
      updatedAt
      deactivationAt
      onboardingCompleted
      onboardingSteps
      groupOnlyContacts
      hasMemo
      primaryEmailCredential {
        id
        state
        email
        confirmedAt
        deactivatedAt
        isPrimary
        createdAt
        updatedAt
      }
      profile {
        userId
        nickname
        statusMessage
        photoUrl
        backgroundUrl
        createdAt
        updatedAt
      }
      login
      loginCredential {
        userId
        login
        createdAt
        updatedAt
      }
      roleObj {
        id
        name
        code
        createdById
        updatedById
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
