import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { zoomVariant } from '../../motionVariants/notificationVariants';
// components
import AlertAction from './alertAction';
import AlertBody from './alertBody';
import AlertIconSuccess from './alertIconSuccess';
import AlertIconFail from './alertIconFail';

const AlertsWrapper = props => {
  const alerts = useSelector(state => state.notification.alerts);

  const renderNotifications = useCallback(() => {
    return alerts.map(({ id, msg, type }) => (
      <motion.div
        key={id}
        className={classnames(
          'notification',
          { 'notification--icon-successful active': type === 'success' || type === 'success_infinity' },
          { 'notification--icon-error active': type === 'fail' },
        )}
        variants={zoomVariant}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <AlertAction alertId={id} />
        {(type === 'success' || type === 'success_infinity') && <AlertIconSuccess />}
        {type === 'fail' && <AlertIconFail />}
        <AlertBody title={type} message={msg} />
      </motion.div>
    ));
  }, [alerts]);

  return (
    <div
      className={classnames('notification-wrapper', {
        active: alerts?.length > 0,
      })}
    >
      <AnimatePresence>
        {alerts?.length > 0 && renderNotifications()}
      </AnimatePresence>
    </div>
  );
};

AlertsWrapper.propTypes = {};

export default AlertsWrapper;
