import { gql } from '@apollo/client';

export const CORE_FEE_FIELDS = gql`
  fragment CoreFeeFields on Strategy {
    id
    asset
    adminBridgeFee
    adminSalesFee
  }
`;

// type adminStrategiesListResponse {
//   list: [Strategy],
//   count:Int!
// }
export const CORE_FEES_RESPONSE_FILEDS = gql`
  ${CORE_FEE_FIELDS}
  fragment CoreFeesResponseFields on adminStrategiesListResponse {
    list {
      ...CoreFeeFields
    }
    count
  }
`;

// type adminStrategyEditResponse{
//   strategy: Strategy
// }
export const CORE_FEES_EDIT_RESPONSE_FIELDS = gql`
  ${CORE_FEE_FIELDS}
  fragment CoreFeesEditResponseFields on adminStrategyEditResponse {
    strategy {
      ...CoreFeeFields
    }
  }
`;
