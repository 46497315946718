import React from 'react';
import PropTypes from 'prop-types';

const AlertIconSuccess = props => {
  return (
    <div className="notification__icon">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="14" stroke="white" strokeWidth="2" />
        <path
          d="M8.75 15C11.4552 17.7052 13.125 19.375 13.125 19.375L21.875 10.625"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

AlertIconSuccess.propTypes = {};

export default AlertIconSuccess;
