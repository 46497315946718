import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import types from '../types';
import { encryptor } from '../encryptor';

const initialState = {
  themes: {
    light: false,
  },
  locale: 'en-US',
  timezone: 'Greenwich',
};

const optionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_APP_OPTIONS:
      return {
        ...state,
        ...payload,
      };
    case types.SET_APP_THEME:
      return {
        ...state,
        themes: { ...payload },
      };
    case types.SET_APP_LOCALE:
      return {
        ...state,
        locale: payload,
      };
    default:
      return state;
  }
};

const optionsConfig = {
  key: 'paybswap_options',
  storage,
  blacklist: [],
  transforms: [encryptor],
};

export default persistReducer(optionsConfig, optionsReducer);
