import { createGlobalStyle, css } from 'styled-components';
import './fonts/NunitoSans-Regular.ttf';
import './fonts/NunitoSans-Bold.ttf';
import './fonts/NunitoSans-Italic.ttf';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {

    margin: 0;
    min-height: 100vh;
    font-family: 'Nunito Sans', 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
  }

  a {
    text-decoration: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .network-icon {
    width: 24px;
    height: 24px;

    display: flex;
    background-color: ${p => p.theme.contrastWhite};
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${p => p.theme.contrastBlack};
  }
  `;
