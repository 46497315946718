import { gql } from '@apollo/client';
import { CORE_SINGLE_ROLE } from './rolesFragment';
import { CORE_PERMISSION } from '../permissions/permissionsFragment';

export const QUERY_ROLE_LIST = gql`
  ${CORE_SINGLE_ROLE}
  query roles {
    roles {
      count
      rows {
        ...CoreSingleRole
      }
    }
  }
`;

export const QUERY_SINGLE_ROLE = gql`
  ${CORE_PERMISSION}
  query role($id: ID!) {
    role(id: $id) {
      id
      name
      code
      modules {
        name
        permissions {
          ...CorePermission
        }
      }
    }
  }
`;
