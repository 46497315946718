import React, { useMemo } from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { themes } from './themeConstant';

export const ThemeProvider = props => {
  const { children } = props;
  const isDarkMode = useSelector(state => state.global.isDarkMode);

  const theme = useMemo(() => {
    if (isDarkMode) return themes.dark;
    return themes.light;
  }, [isDarkMode]);

  return (
    <OriginalThemeProvider theme={theme}>
      {React.Children.only(children)}
    </OriginalThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
