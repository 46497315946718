/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routes from '../../../routes';
// icons
import { ReactComponent as DashboardIcon } from '../../widgets/icons/navigation/dashboard.svg';
import { ReactComponent as FeesIcon } from '../../widgets/icons/navigation/fees.svg';
import { ReactComponent as NftIcon } from '../../../assets/icons/rocket.svg';
import { ReactComponent as UsersIcon } from '../../widgets/icons/navigation/users.svg';
import { ReactComponent as ModsIcon } from '../../../assets/icons/mods.svg';
import ExitIcon from '../../widgets/icons/common/exitIcon';

// logo
import logoImagePath from '../../../styles/images/logo.png';
import types from '../../../redux/types';
import { axiosApiUnauth, axiosApiWithAuth } from '../../../utils/axiosApi';

const styleCorrection = {
  padding: '16px 10px',
  overflow: 'hidden',
  textAlign: 'center',
};

const Sidebar = props => {
  const { pathname } = useLocation();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const navigation = useMemo(() => {
    return [
      {
        id: '001',
        title: 'Dashboard',
        path: routes.Dashboard.path,
        icon: (
          <DashboardIcon
            className={
              location.pathname === routes.Dashboard.path
                ? 'icon-no-stroke'
                : ''
            }
            width="32px"
            height="32px"
          />
        ),
      },
      /* {
        id: '002',
        title: 'NFT',
        path: routes.Nft.path,
        child: 'nft',
        icon: (
          <NftIcon
            className={
              location.pathname.includes('nft') ? 'icon-no-stroke' : 'no'
            }
            width="32px"
            height="32px"
          />
        ),
      }, */
      {
        id: '003',
        title: 'Fees',
        path: routes.Fees.path,
        icon: <FeesIcon width="32px" height="32px" />,
      },
      {
        id: '004',
        title: 'Users',
        path: routes.Users.path,
        icon: <UsersIcon width="32px" height="32px" />,
      },
      {
        id: '005',
        title: 'Mods',
        path: routes.Mods.path,
        icon: (
          <ModsIcon
            width="32px"
            height="32px"
            className={
              location.pathname.includes('mods') ? 'icon-no-stroke' : 'no'
            }
          />
        ),
      },
    ];
  }, [location]);

  const renderNavigation = useCallback(() => {
    return navigation.map(({ id, title, path, icon, child }) => {
      return (
        <li key={id}>
          <a
            style={styleCorrection}
            className={classnames({ active: path === location.pathname })}
            onClick={() => history.push(path)}
          >
            <div
              className={`nav-icon ${
                path === location.pathname || location.pathname.includes(child)
                  ? 'active-icon'
                  : ''
              }`}
              style={{
                backgroundColor:
                  path === location.pathname ||
                  location.pathname.includes(child)
                    ? '#0073F7'
                    : 'transparent',
              }}
            >
              {icon}
            </div>
            {title}
          </a>
        </li>
      );
    });
  }, [navigation, location, history]);

  const handleLogout = useCallback(async () => {
    //      const res = await axiosApiWithAuth.post('/auth/logout');

    // console.log(res)

    dispatch({
      type: types.RESET_AUTH,
    });

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, [dispatch]);

  return (
    <AnimatePresence>
      <motion.div
        className="sidebar"
        initial={{ width: !open ? 56 : 120, transition: { ease: 'easeIn' } }}
        animate={{ width: open ? 120 : 56, transition: { ease: 'easeIn' } }}
      >
        <div className="sidebar-wrapper">
          <div className="sidebar-wrapper-nav">
            <nav className="sidebar-nav">
              <ul className="sidebar-nav__list">{renderNavigation()}</ul>
            </nav>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
