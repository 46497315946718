import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* feesDataWorker({ type, payload }) {
  yield put({ type: types.SET_FEES_DATA, payload });
}

function* feesFilterWorker({ type, payload }) {
  yield put({ type: types.SET_FEES_FILTER, payload });
}

export function* feesWatcher() {
  yield takeEvery(types.WATCH_FEES_DATA, feesDataWorker);
  yield takeEvery(types.WATCH_FEES_FILTER, feesFilterWorker);
}
