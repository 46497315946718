// eslint-disable-next-line import/no-unresolved
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { store } from '../redux/store';

const API_URI =
  process.env.REACT_APP_ENVIRONMENT !== 'prod'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URI;

const httpLink = createUploadLink({
  uri: API_URI,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const { token } = store.getState().auth;

  return {
    headers: {
      ...headers,
      Authorization: token?.length > 0 ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphqlErrors, networkErrors }) => {
  if (graphqlErrors) {
    // eslint-disable-next-line array-callback-return
    graphqlErrors.map((message, location, path) => {
      console.log(`Error ${message}`);
    });
  }
});

const link = from([errorLink, authLink.concat(httpLink)]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    resultCaching: true,
    typePolicies: {
      Query: {
        fields: {},
      },
      User: {
        keyFields: ['id'],
      },
    },
  }),
});
