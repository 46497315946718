export const standartVariant = {
  initial: {
    opacity: 0,
    overflow: 'hidden',
    transition: {
      type: 'spring',
    },
  },
  animate: {
    opacity: 1,
    overflow: 'hidden',
    transition: {
      type: 'spring',
    },
    transitionEnd: {
      overflow: 'auto',
    },
  },
  exit: {
    opacity: 0,
    overflow: 'hidden',
    transition: {
      duration: 0.5,
      delay: 0.25,
    },
  },
};
