/* eslint-disable no-param-reassign */
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptor } from '../encryptor';
import types from '../types';

const initialState = {
  data: [],
  filter: {
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
    criteria: '',
    sort: '',
    direction: '',
  },
};

const poolsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_POOLS_DATA:
      return {
        ...state,
        data: [...payload],
      };
    case types.SET_POOLS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    default:
      return state;
  }
};

const poolsConfig = {
  key: 'paybswap_pools',
  storage,
  blacklist: [],
  transforms: [encryptor],
};

export default persistReducer(poolsConfig, poolsReducer);
