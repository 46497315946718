import types from '../types';

const initialState = {
  alerts: [],
};

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case types.REM_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(item => item.id !== payload),
      };
    default:
      return state;
  }
};

export default notificationReducer;
