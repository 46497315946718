import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const encryptRedux = 'e-n-c-r-y-p-t-r-e-d-u-x';

export const encryptor = createTransform(
  (inboundState, key) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      encryptRedux,
    );
    return cryptedText.toString();
  },
  (outboundState, key) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(outboundState, encryptRedux);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  },
);
