import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptor } from '../encryptor';
import types from '../types';

const initialState = {
  email: '',
  fullName: '',
  walletAddress: '',
  roleId: 0,
  role: {},
  roleName: '',
  token: '',
  status: 0,
  _id: '',
  permissions: {},

  isSaving: false,
  isSavingComplete: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_AUTH_DETAIL:
      return {
        ...state,
        ...payload,
      };

    case types.SET_PROFILE_DETAIL:
      return {
        ...state,
        ...payload,
      };

    case types.RESET_AUTH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const authConfig = {
  key: 'paybswap_auth',
  storage,
  blacklist: [],
  transforms: [encryptor],
};

export default persistReducer(authConfig, authReducer);
