import { put, takeEvery, call } from 'redux-saga/effects';
import { axiosApiWithAuth } from '../../utils/axiosApi';
import types from '../types';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* modalSetWorker({ type, payload }) {
  yield put({ type: types.SET_MODAL, payload });
}

function* modalLockWorker({ type, payload }) {
  yield put({ type: types.LOCK_MODAL, payload });
}

function* modalRemWorker({ type, payload }) {
  yield put({ type: types.REM_MODAL, payload });
}


function* verifyOTP({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.post('/auth/isValidOTP', payload);

    const { success } = res.data;

    if (success) yield put({ type: types.SET_VERIFY_OTP_SUCCESS, payload });
  } catch (e) {
    console.error('Error @ Verify OTP: ', e.response.data.message);
    yield put({ type: types.SET_VERIFY_OTP_ERROR, payload: e.response.data.message });
  }
}

function* confirmPassword({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.post('/auth/changePwd', payload);

    const { data, success } = res.data;

    if (success) {
      yield put({ type: types.SET_NEW_PASSWORD_SUCCESS, payload });
    }
  } catch (e) {
    console.error('Error @ Verify OTP: ', e);
  }
}

function* changePassword({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.post('/mod/profile/changePwd', payload);

    const { data, success } = res?.data;

    if (success) {
      yield put({ type: types.SET_CHANGE_PASSWORD_SUCCESS, payload });
    }
  } catch (e) {
    yield put({ type: types.SET_CHANGE_PASSWORD_ERROR, payload: e.response.data.message });
  }
}

export function* modalsWatcher() {
  yield takeEvery(types.WATCH_SET_MODAL, modalSetWorker);
  yield takeEvery(types.WATCH_LOCK_MODAL, modalLockWorker);
  yield takeEvery(types.WATCH_REM_MODAL, modalRemWorker);
  yield takeEvery(types.WATCH_MODAL_VERIFY_OTP, verifyOTP);
  yield takeEvery(types.WATCH_SET_NEW_PASSWORD, confirmPassword);
  yield takeEvery(types.WATCH_CHANGE_PASSWORD, changePassword);
}
