const lightTheme = {
  primary: '#0073F7',
  secondary: '#fff',

  textPrimary: '#232323',
  textSecondary: '#8E8E8E',
  textSuccess: '#009E51',
  textStable: '#8736CB',
  textError: '#9E314E',
  textWarning: '#CF9F2B',
  textBasic: '#000',

  gray: '#909090',

  inputBackground: '#fff',

  buttonTextPrimary: '#fff',
  buttonTextSecondary: '#0073F7',
  buttonBackground: '#0073F7',
  backgroundHover: '#4c9df8',
  backgroundActive: '#0049C3',

  itemHover: '#E7E7E7',

  error: '#F44336',
  error2: '#BA000D',
  success: '#E0FBF1',
  info: '#3BA9E0',
  warning: '#FCF1DB',
  stable: '#E4CBFF',
  basic: '#fff',

  hoverError: 'rgba(244,67,54, .75)',
  hoverError2: 'rgba(186,0,13, .75)',

  successBackground: '#E0FBF1',
  warningBackground: '#FCF1DB',

  contrastWhite: '#fff',
  contrastBlack: '#000',
};

const darkTheme = {
  primary: '#341760',
  secondary: '#fff',

  textPrimary: '#3E3E3E',
  textSecondary: '#8E8E8E',
  textSuccess: '#489576',
  textStable: '#8736CB',
  textError: '#9E314E',
  textWarning: '#CF9F2B',
  textBasic: '#fff',

  inputBackground: '#fff',

  itemHover: '#E7E7E7',

  error: '#FCE7EE',
  success: '#E0FBF1',
  info: '#3BA9E0',
  warning: '#FCF1DB',
  stable: '#E4CBFF',
  basic: '#fff',

  successBackground: '#E0FBF1',
  warningBackground: '#FCF1DB',

  contrastWhite: '#000',
  contrastBlack: '#fff',
};

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
