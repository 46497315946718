import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import globalReducer from './global/globalReducer';
import optionsReducer from './options/optionsReducer';
import notificationReducer from './notifications/notificationsReducer';
import modalsReducer from './modals/modalsReducer';
import usersReducer from './users/usersReducer';
import strategiesReducer from './strategies/strategiesReducer';
import poolsReducer from './pools/poolsReducer';
import feesReducer from './fees/feesReducer';
import nftReducer from './nft/nftReducer';
import nftlpReducer from './nft-launchpad/nft-lpReducer';
import rolesReducer from './roles/rolesReducer';
import permissionsReducer from './permissions/permissionsReducer';
import dashboardReducer from './dashboard/dashboardReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  options: optionsReducer,
  notification: notificationReducer,
  modals: modalsReducer,
  users: usersReducer,
  strategies: strategiesReducer,
  pools: poolsReducer,
  fees: feesReducer,
  nft: nftReducer,
  nftlp: nftlpReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  global: globalReducer,
  dashboard: dashboardReducer
});

export default rootReducer;
