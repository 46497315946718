import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptor } from '../encryptor';
import types from '../types';

const initialState = {
  isDarkMode: false,
};

const globalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_GLOBAL_THEME:
      return {
        ...state,
        isDarkMode: !state.isDarkMode,
      };
   
    default:
      return state;
  }
};

const globalConfig = {
  key: 'paybswap_global',
  storage,
  blacklist: [],
  transforms: [encryptor],
};

export default persistReducer(globalConfig, globalReducer);
