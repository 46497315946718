import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptor } from '../encryptor';
import types from '../types';

const initialState = {
  marketValue: 0,
  nft: 0,
  transaction: 0,
  user: 0,
  loading: false,
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DASHBOARD_DETAIL:
      return {
        ...state,
        ...payload,
      };
    case types.RESET_DASHBOARD:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const dashboardConfig = {
  key: 'paybswap_dashboard',
  storage,
  blacklist: [],
  transforms: [encryptor],
};

export default persistReducer(dashboardConfig, dashboardReducer);
