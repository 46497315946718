import { fork } from 'redux-saga/effects';
import { authWatcher } from './auth/authSaga';
import { dashboardWatcher } from './dashboard/dashboardSaga';
import { optionsWatcher } from './options/optionsSaga';
import { notificationWatcher } from './notifications/notificationsSaga';
import { modalsWatcher } from './modals/modalsSaga';
import { usersWatcher } from './users/usersSaga';
import { strategiesWatcher } from './strategies/strategiesSaga';
import { poolsWatcher } from './pools/poolsSaga';
import { feesWatcher } from './fees/feesSaga';
import { nftWatcher } from './nft/nftSaga';
import { rolesWatcher } from './roles/rolesSaga';
import { permissionsWatcher } from './permissions/permissionsSaga';

export function* rootSaga() {
  yield fork(authWatcher);
  yield fork(dashboardWatcher);
  yield fork(optionsWatcher);
  yield fork(notificationWatcher);
  yield fork(modalsWatcher);
  yield fork(usersWatcher);
  yield fork(strategiesWatcher);
  yield fork(poolsWatcher);
  yield fork(feesWatcher);
  yield fork(nftWatcher);
  yield fork(rolesWatcher);
  yield fork(permissionsWatcher);
}
