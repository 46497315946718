import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import types from '../../redux/types';

const AlertAction = ({ alertId }) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch({ type: types.WATCH_REM_ALERT, payload: alertId });
  }, [dispatch, alertId]);

  return (
    <div className="notification__close">
      <button type="button" onClick={handleClose}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.48929 15.0259L4.42863 13.9652L13.9692 4.42462L15.0299 5.48528L5.48929 15.0259Z"
            fill="#2B2D33"
          />
          <path
            d="M15.0299 13.9706L13.9692 15.0312L4.42326 5.48528L5.48392 4.42462L15.0299 13.9706Z"
            fill="#2B2D33"
          />
        </svg>
      </button>
    </div>
  );
};

AlertAction.propTypes = {
  alertId: PropTypes.string.isRequired,
};

export default AlertAction;
