/* eslint-disable no-param-reassign */
import { statement } from '@babel/template';
import types from '../types';

const initialState = {
  nftFee: {
    status: false,
    processing: false,
    title: 'Send NFT',
    id: undefined,
  },
  userEdit: {
    status: false,
    processing: false,
    title: 'Edit user',
    id: '',
    role: {
      id: '',
      title: '',
      nftACL: false,
      feeACL: false,
      poolACL: false,
    },
  },
  feeEdit: {
    status: false,
    processing: false,
    title: 'Edit fee',
    strategy: {
      id: '',
      asset: '',
      bridgeFee: '',
      salesFee: '',
    },
  },
  roleEdit: {
    status: false,
    processing: false,
    title: 'Edit role',
    role: {
      id: '',
      title: '',
      permissions: [],
    },
  },
  roleAdd: {
    status: false,
    processing: false,
    title: 'Add role',
    role: {
      title: '',
      permissions: [],
    },
  },
  poolEdit: {
    status: false,
    processing: false,
    title: 'Edit pool',
    id: '',
    asset: '',
    period: '',
  },
  strategyEdit: {
    status: false,
    processing: false,
    title: 'Edit strategy',
    id: '',
    iconUrl: '',
    asset: '',
  },
  withdrawEdit: {
    status: false,
    processing: false,
    title: 'Edit withdraw',
    id: '',
    asset: '',
  },
  recoveryEmail: {
    status: false,
    processing: false,
    title: 'FORGOT PASSWORD',
    id: '',
    asset: '',
    message: '',
  },
  confirmOTP: {
    status: false,
    processing: false,
    title: 'CONFIRM',
    id: '',
    asset: '',
    message: '',
  },
  changePassword: {
    status: false,
    processing: false,
    title: 'CHANGE PASSWORD',
    id: '',
    success: false,
    message: '',
  },
  restrictModal: {
    status: false,
    processing: false,
    title: 'RESTRICT',
    id: '',
    success: false,
  },
  blockModal: {
    status: false,
    processing: false,
    title: 'BLOCK',
    id: '',
    success: false,
  },
  deleteModal: {
    status: false,
    processing: false,
    title: 'DELETE',
    id: '',
    success: false,
  },
  inviteModal: {
    status: false,
    processing: false,
    title: 'INVITE ADMINISTRATOR',
    id: '',
    success: false,
  },
  changeRoleModal: {
    status: false,
    processing: false,
    title: 'ROLE',
    id: '',
    success: false,
  },
  addRoleModal: {
    status: false,
    processing: false,
    title: 'ADD ROLE',
    id: '',
    success: false,
  }
};

const modalsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // { type, data } = payload
    case types.SET_MODAL:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload.data,
        },
      };
    case types.LOCK_MODAL:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          status: false,
          processing: true,
        },
      };
    case types.REM_MODAL:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          status: false,
          processing: false,
          success: false,
          message: '',
        },
      };
    case types.SET_SEND_RECOVERY_EMAIL_SUCCESS:
      return {
        ...state,
        recoveryEmail: {
          ...state.recoveryEmail,
          status: false,
          processing: false,
          message: '',
        },
        confirmOTP: {
          ...state.confirmOTP,
          status: true,
          processing: false,
        },
      };

    case types.SET_SEND_RECOVERY_EMAIL_ERROR:
      return {
        ...state,
        recoveryEmail: {
          ...state.recoveryEmail,
          message: payload,
        },
      };

    case types.SET_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        confirmOTP: {
          ...state.confirmOTP,
          status: false,
          processing: false,
          message: '',
        },
        changePassword: {
          ...state.changePassword,
          status: true,
          processing: false,
        },
      };

    case types.SET_VERIFY_OTP_ERROR:
      return {
        ...state,
        confirmOTP: {
          ...state.confirmOTP,
          message: payload,
        },
      };

    case types.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          title: 'PASSWORD CHANGED SUCCESSFULLY',
          success: true,
        },
      };
    case types.SET_MODAL_BAN_NFT_SUCCESS:
      return {
        ...state,
        banModal: {
          ...state.banModal,
          success: true,
        },
      };
    case types.SET_MODAL_UNBAN_NFT_SUCCESS:
      return {
        ...state,
        unbanModal: {
          ...state.unbanModal,
          success: true,
        },
      };
    case types.SET_MODAL_RESTRICT_NFT_SUCCESS:
      return {
        ...state,
        restrictModal: {
          ...state.restrictModal,
          success: true,
        },
      };
    case types.SET_MODAL_UNRESTRICT_NFT_SUCCESS:
      return {
        ...state,
        restrictModal: {
          ...state.restrictModal,
          success: true,
        },
      };

    case types.SET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          success: true,
          message: '',
        },
      };
    case types.SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          message: payload,
        },
      };

    default:
      return state;
  }
};

export default modalsReducer;
