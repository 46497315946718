import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { UPDATE_FEE, FEES } from '../../queries/fees/feesQueries';
import { dropIn } from '../../motionVariants/modalVariants';
// redux
import types from '../../redux/types';
// components
import Backdrop from './backdrop';
import Spinner from '../widgets/spinner/spinner';
// icons
import CloseIcon from '../widgets/icons/modals/closeIcon';

const FeesEdit = props => {
  const dispatch = useDispatch();
  const { status, processing, title, strategy } = useSelector(
    state => state.modals.feeEdit,
  );

  const handleClose = useCallback(
    e => {
      dispatch({ type: types.WATCH_REM_MODAL, payload: { type: 'feeEdit' } });
    },
    [dispatch],
  );

  const [update, { client }] = useMutation(UPDATE_FEE, {
    onError: err => {
      dispatch({
        type: types.WATCH_SET_ALERT,
        payload: { msg: err.message, type: 'fail' },
      });
      handleClose();
    },
    onCompleted: async data => {
      try {
        dispatch({
          type: types.WATCH_SET_ALERT,
          payload: { msg: 'Updated successfully', type: 'success' },
        });

        await client.refetchQueries({
          include: [FEES],
        });
        handleClose();
      } catch (err) {
        return err;
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handleChange = useCallback(
    (e, index) => {
      let { value } = e.target;

      if (value.includes('-')) {
        value = Math.abs(+value);
      }

      const updData = {
        strategy: {
          ...strategy,
          [index]: value.toString(),
        },
      };

      dispatch({
        type: types.WATCH_SET_MODAL,
        payload: { type: 'feeEdit', data: updData },
      });
    },
    [dispatch, strategy],
  );

  const inputs = useMemo(() => {
    return [
      { id: 'bridgeFee', name: 'Bridge Fee', value: strategy.bridgeFee },
      { id: 'salesFee', name: 'Withdraw Fee', value: strategy.salesFee },
    ];
  }, [strategy]);

  const renderInputs = useCallback(() => {
    return inputs.map(input => {
      return (
        <div className="input " key={input.id}>
          <label htmlFor={`${input.name}`}>
            <p className="input__name">{input.name}</p>
            <div className="input-wrapper">
              <input
                id={`${input.name}`}
                className="input-item"
                type="number"
                min="0"
                step="1"
                pattern="\d+"
                placeholder={`Enter ${input.name}`}
                value={input.value}
                onChange={e => handleChange(e, input.id)}
              />
            </div>
          </label>
        </div>
      );
    });
  }, [inputs, handleChange]);

  const handleSubmit = useCallback(async () => {
    try {
      const variables = {
        strategy: strategy.id,
        bridgeFee: strategy.bridgeFee,
        salesFee: strategy.salesFee,
      };

      await update({ variables });
    } catch (err) {
      return new Error(err);
    }
  }, [strategy, update]);

  return (
    <Backdrop onClick={processing ? null : handleClose}>
      <motion.div
        className="popup-window__inside"
        onClick={e => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {processing && <Spinner />}
        <div className="popup">
          <button className="popup__close" type="button" onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className="popup-header">
            <p className="popup-header__title">{title}</p>
          </div>
          <div className="popup-body">
            <div className="select-block">
              <p className="select-block__name">{`Token: ${strategy.asset}`}</p>
            </div>
            {renderInputs()}
            <button
              className="button button--full-width"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

FeesEdit.defaultProps = {};

FeesEdit.propTypes = {};

export default FeesEdit;
