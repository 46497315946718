import React from 'react';
import { motion, useMotionValue, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

// svg animation options
// pathLength
// pathSpacing
// pathOffset

const svgVariants = {
  initial: {
    scale: 1,
  },
  animate: {
    scale: [0.8, 1.0, 0.8],
    transition: {
      type: 'easeInOut',
      duration: 3.5,
      repeat: Infinity,
    },
  },
};

const pathVariants = {
  initial: {
    pathLength: 1,
  },
  animate: {
    pathLength: [0, 1, 0],
    transition: {
      type: 'easeInOut',
      duration: 3.5,
      repeat: Infinity,
    },
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '75px',
  height: '75px',
  transform: 'translate(-50%, -50%)',
};

const Spinner = props => {
  const lightTheme = useSelector(state => state?.options?.themes?.light);

  const pathLength = useMotionValue(0);

  return (
    <div style={style} className="spinner-container">
      <AnimatePresence>

        <motion.svg
          version="1.1"
          id="L7"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 100 100"
          xmlSpace="preserve"
          style={{ originX: '50%', originY: '50%' }}
        >
          <motion.path
            fill="#6496e8"
            d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  L82,35.7z"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </motion.path>
        </motion.svg>
      </AnimatePresence>
    </div>
  );
};

// {/* <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
//   viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
//     <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
//       <animateTransform
//          attributeName="transform"
//          attributeType="XML"
//          type="rotate"
//          dur="1s"
//          from="0 50 50"
//          to="360 50 50"
//          repeatCount="indefinite" />
//   </path>
// </svg> */}

export default Spinner;
