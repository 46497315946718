import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* permissionsDataWorker({ type, payload }) {
  yield put({ type: types.SET_PERMISSIONS_DATA, payload });
}

export function* permissionsWatcher() {
  yield takeEvery(types.WATCH_PERMISSIONS_DATA, permissionsDataWorker);
}
