import React from 'react';
import PropTypes from 'prop-types';

const SelectIcon = props => {
  return (
    <span className="select__current-arrow">
      <svg
        className="stroke"
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
      </svg>
    </span>
  );
};

SelectIcon.propTypes = {};

export default SelectIcon;
