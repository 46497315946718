/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  data: [],
};

const permissionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PERMISSIONS_DATA:
      return {
        ...state,
        data: [...payload],
      };
    default:
      return state;
  }
};

export default permissionsReducer;
