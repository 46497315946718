/* eslint-disable import/no-cycle */
import React from 'react';

const routes = {
  ConfirmEmail: {
    path: '/auth/confirm-email',
    component: React.lazy(() => import('../pages/signin/signin')),
  },
  ChangePassword: {
    path: '/auth/change-email-password',
    component: React.lazy(() => import('../pages/signin/signin')),
  },
  Signin: {
    path: '/',
    component: React.lazy(() => import('../pages/signin/signin')),
  },
  Signup: {
    path: '/signup',
    component: React.lazy(() => import('../pages/signup/signup')),
  },
  Dashboard: {
    path: '/dashboard/stats',
    component: React.lazy(() => import('../pages/dashboard')),
  },
  Blocked: {
    path: '/dashboard/blocked',
    component: React.lazy(() => import('../pages/blocked/blocked')),
  },
  Users: {
    path: '/dashboard/users',
    component: React.lazy(() => import('../pages/users/users')),
  },
  Fees: {
    path: '/dashboard/fees',
    component: React.lazy(() => import('../pages/fees/fees')),
  },
  Nft: {
    path: '/dashboard/nft',
    component: React.lazy(() => import('../pages/nft')),
  },
  NftDetail: {
    path: '/dashboard/nft/:id',
    component: React.lazy(() => import('../pages/nft/nftDetails')),
  },
  NftLaunchpad: {
    path: '/nft-lp',
    component: React.lazy(() => import('../pages/nft-launchpad/index')),
  },
  Mods: {
    path: '/dashboard/mods',
    component: React.lazy(() => import('../pages/mods')),
  },
  Settings: {
    path: '/dashboard/settings',
    component: React.lazy(() => import('../pages/settings/settings')),
    administrator: {
      path: '/dashboard/settings/administrator',
    },
    permission: {
      path: '/dashboard/settings/permission',
    },
  },
  Profile: {
    path: '/dashboard/profile',
    component: React.lazy(() => import('../pages/profile')),
  },
};

export default routes;
