import React from 'react';
import PropTypes from 'prop-types';

const AlertIconFail = props => {
  return (
    <div className="notification__icon">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="14" stroke="white" strokeWidth="2" />
        <path
          d="M21 9L9 21"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9L21 21"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

AlertIconFail.propTypes = {};

export default AlertIconFail;
