import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { standartVariant } from '../../motionVariants/backdropVariants';

const Backdrop = ({ children, onClick, ref }) => {
  return (
    <motion.div
      ref={ref}
      className="popup-window"
      onClick={onClick}
      variants={standartVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

Backdrop.defaultProps = {
  onClick: null,
};

Backdrop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
};

export default Backdrop;
