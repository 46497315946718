const types = {
  WATCH_TOKEN: 'WATCH_TOKEN',
  SET_TOKEN: 'SET_TOKEN',

  WATCH_AUTH_DETAIL: 'WATCH_AUTH_DETAIL',
  SET_AUTH_DETAIL: 'SET_AUTH_DETAIL',

  WATCH_USER: 'WATCH_USER',
  SET_USER: 'SET_USER',

  RESET_AUTH: 'RESET_AUTH',

  WATCH_MODAL_RECOVER_EMAIL: 'WATCH_MODAL_RECOVER_EMAIL',
  SET_MODAL_RECOVER_EMAIL: 'SET_MODAL_RECOVER_EMAIL',

  WATCH_MODAL_VERIFY_OTP: 'WATCH_MODAL_VERIFY_OTP',
  SET_MODAL_VERIFY_OTP: 'SET_MODAL_VERIFY_OTP',

  SET_MODAL_BAN_NFT_SUCCESS: 'SET_MODAL_BAN_NFT_SUCCESS',
  SET_MODAL_UNBAN_NFT_SUCCESS: 'SET_MODAL_UNBAN_NFT_SUCCESS',
  SET_MODAL_RESTRICT_NFT_SUCCESS: 'SET_MODAL_RESTRICT_NFT_SUCCESS',
  SET_MODAL_UNRESTRICT_NFT_SUCCESS: 'SET_MODAL_UNRESTRICT_NFT_SUCCESS',

  SET_SEND_RECOVERY_EMAIL_SUCCESS: 'SET_SEND_RECOVERY_EMAIL_SUCCESS',
  SET_SEND_RECOVERY_EMAIL_ERROR: 'SET_SEND_RECOVERY_EMAIL_ERROR',

  SET_VERIFY_OTP_SUCCESS: 'SET_VERIFY_OTP_SUCCESS',
  SET_VERIFY_OTP_ERROR: 'SET_VERIFY_OTP_ERROR',

  WATCH_SET_NEW_PASSWORD: 'WATCH_SET_NEW_PASSWORD', // FORGOT PASSWORD
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',

  WATCH_CHANGE_PASSWORD: 'WATCH_CHANGE_PASSWORD', // CHANGE PASSWORD IN PROFILE
  SET_CHANGE_PASSWORD_SUCCESS: 'SET_CHANGE_PASSWORD_SUCCESS',
  SET_CHANGE_PASSWORD_ERROR: 'SET_CHANGE_PASSWORD_ERROR',

  WATCH_APP_OPTIONS: 'WATCH_APP_OPTIONS',
  SET_APP_OPTIONS: 'SET_APP_OPTIONS',

  WATCH_APP_THEME: 'WATCH_APP_THEME',
  SET_APP_THEME: 'SET_APP_THEME',

  WATCH_APP_LOCALE: 'WATCH_APP_LOCALE',
  SET_APP_LOCALE: 'SET_APP_LOCALE',

  WATCH_SET_ALERT: 'WATCH_SET_ALERT',
  SET_ALERT: 'SET_ALERT',

  WATCH_REM_ALERT: 'WATCH_REM_ALERT',
  REM_ALERT: 'REM_ALERT',

  WATCH_SET_MODAL: 'WATCH_SET_MODAL',
  SET_MODAL: 'SET_MODAL',

  WATCH_LOCK_MODAL: 'WATCH_LOCK_MODAL',
  LOCK_MODAL: 'LOCK_MODAL',

  WATCH_REM_MODAL: 'WATCH_REM_MODAL',
  REM_MODAL: 'REM_MODAL',

  WATCH_PERMISSIONS_DATA: 'WATCH_PERMISSIONS_DATA',
  SET_PERMISSIONS_DATA: 'SET_PERMISSIONS_DATA',

  WATCH_USERS_DATA: 'WATCH_USERS_DATA',
  SET_USERS_DATA: 'SET_USERS_DATA',

  WATCH_USERS_FILTER: 'WATCH_USERS_FILTER',
  SET_USERS_FILTER: 'SET_USERS_FILTER',

  WATCH_STRATEGIES_DATA: 'WATCH_STRATEGIES_DATA',
  SET_STRATEGIES_DATA: 'SET_STRATEGIES_DATA',

  WATCH_STRATEGIES_FILTER: 'WATCH_STRATEGIES_FILTER',
  SET_STRATEGIES_FILTER: 'SET_STRATEGIES_FILTER',

  WATCH_AUTH_NETWORK: 'WATCH_AUTH_NETWORK',
  SET_AUTH_NETWORK: 'SET_AUTH_NETWORK',

  WATCH_POOLS_DATA: 'WATCH_POOLS_DATA',
  SET_POOLS_DATA: 'SET_POOLS_DATA',

  WATCH_POOLS_FILTER: 'WATCH_POOLS_FILTER',
  SET_POOLS_FILTER: 'SET_POOLS_FILTER',

  WATCH_FEES_DATA: 'WATCH_FEES_DATA',
  SET_FEES_DATA: 'SET_FEES_DATA',

  WATCH_FEES_FILTER: 'WATCH_FEES_FILTER',
  SET_FEES_FILTER: 'SET_FEES_FILTER',

  WATCH_NFT_DATA: 'WATCH_NFT_DATA',
  SET_NFT_DATA: 'SET_NFT_DATA',

  SET_NFT_FILTER_START: 'SET_NFT_FILTER_START',

  WATCH_NFT_LP_DATA: 'WATCH_NFT_LP_DATA',
  SET_NFT_LP_DATA: 'SET_NFT_LP_DATA',

  WATCH_NFT_LP_FILTER: 'WATCH_NFT_LP_FILTER',
  SET_NFT_LP_FILTER: 'SET_NFT_LP_FILTER',

  SET_NFT_LP_FILTER_START: 'SET_NFT_LP_FILTER_START',

  WATCH_ROLES_DATA: 'WATCH_ROLES_DATA',
  SET_ROLES_DATA: 'SET_ROLES_DATA',

  WATCH_ROLES_FILTER: 'WATCH_ROLES_FILTER',
  SET_ROLES_FILTER: 'SET_ROLES_FILTER',

  UPDATE_METAMASK_WALLET: 'UPDATE_METAMASK_WALLET',
  UPDATE_WALLETCONNECT_WALLET: 'UPDATE_WALLETCONNECT_WALLET',
  UPDATE_TRUSTWALLET_WALLET: 'UPDATE_TRUSTWALLET_WALLET',

  // ---------- NFT ----------

  SET_NFT_LIST: 'SET_NFT_LIST',
  WATCH_NFT_LIST: 'WATCH_NFT_LIST',

  SET_NFT_FILTER: 'SET_NFT_FILTER',
  WATCH_NFT_FILTER: 'WATCH_NFT_FILTER',

  SET_NFT_DETAIL: 'SET_NFT_DETAIL',
  WATCH_NFT_DETAIL: 'WATCH_NFT_DETAIL',

  WATCH_BAN_NFT: 'WATCH_BAN_NFT',
  WATCH_BAN_NFT_SUCCESS: 'WATCH_BAN_NFT_SUCCESS',

  WATCH_UNBAN_NFT: 'WATCH_UNBAN_NFT',
  WATCH_UNBAN_NFT_SUCCESS: 'WATCH_UNBAN_NFT_SUCCESS', 
  
  WATCH_DELETE_ITEM: 'WATCH_DELETE_ITEM',
  WATCH_DELETE_ITEM_SUCCESS: 'WATCH_DELETE_ITEM_SUCCESS',  

  WATCH_BLOCK_ITEM: 'WATCH_BLOCK_ITEM',
  WATCH_BLOCK_ITEM_SUCCESS: 'WATCH_BLOCK_ITEM_SUCCESS',

  WATCH_UNBLOCK_ITEM: 'WATCH_UNBLOCK_ITEM',
  WATCH_UNBLOCK_ITEM_SUCCESS: 'WATCH_UNBLOCK_ITEM_SUCCESS',

  WATCH_RESTRICT_NFT: 'WATCH_RESTRICT_NFT',
  WATCH_RESTRICT_NFT_SUCCESS: 'WATCH_RESTRICT_NFT_SUCCESS',
  WATCH_UNRESTRICT_NFT: 'WATCH_UNRESTRICT_NFT',
  WATCH_UNRESTRICT_NFT_SUCCESS: 'WATCH_UNRESTRICT_NFT_SUCCESS',

  WATCH_NFT_HISTORY: 'WATCH_NFT_HISTORY',
  SET_NFT_HISTORY: 'SET_NFT_HISTORY',

  SET_NFT_LOADING: 'SET_NFT_LOADING',

  // ---------- Dashboard ----------
  WATCH_DASHBOARD_DETAIL: 'WATCH_DASHBOARD_DETAIL',
  SET_DASHBOARD_DETAIL: 'SET_DASHBOARD_DETAIL',
  RESET_DASHBOARD: 'RESET_DASHBOARD',

  // ---------- Profile ----------
  WATCH_PROFILE_DETAIL: 'WATCH_PROFILE_DETAIL',
  SET_PROFILE_DETAIL: 'SET_PROFILE_DETAIL',

  WATCH_UPDATE_PROFILE: 'WATCH_UPDATE_PROFILE',
  SET_UPDATE_PROFILE: 'SET_UPDATE_PROFILE',

  // ---------- Mods ----------
  WATCH_MOD_DETAIL: 'WATCH_MOD_DETAIL',
  SET_MOD_DETAIL: 'SET_MOD_DETAIL',

};

export default types;
