import { put, takeEvery } from 'redux-saga/effects';
import { stringify } from 'querystring';
import { axiosApiWithAuth } from '../../utils/axiosApi';
import types from '../types';
import { API_URL } from '../../config';

function* nftDataWorker({ type, payload }) {
  yield put({
    type: types.SET_NFT_LOADING,
    payload: true,
  });
  try {
    const filter = payload;

    const res = yield axiosApiWithAuth.get(`/nft?${stringify(filter)}`);

    const { data, success } = res.data;

    if (success) {
      const currentList = data.rows;
      const totalCount = data.count;
      yield put({
        type: types.SET_NFT_LIST,
        payload: { currentList, totalCount },
      });
      yield put({
        type: types.SET_NFT_LOADING,
        payload: false,
      });
    }
  } catch (e) {
    console.error('Error @ Fetch NFT List: ', e);
  }
}

function* nftFilterWorker({ type, payload }) {
  yield put({ type: types.SET_NFT_FILTER, payload });
}

function* nftHistoryWorker({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.get(
      `/nft/history?${stringify(payload)}`,
    );

    const { data, success } = res.data;

    if (success) {
      yield put({ type: types.SET_NFT_HISTORY, payload: data });
    }
  } catch (e) {
    console.error('Error @ Unbanning NFT ID: ', e);
  }
}

function* nftDetailWorker({ type, payload }) {
  try {
    const res = yield axiosApiWithAuth.get(`/nft/${payload.id}`);

    const { data, success } = res.data;
    if (success) {
      const selectedNft = {
        _id: data._id,
        tokenId: data.tokenId,
        nftName: data.name,
        artistName: '',
        ownerId: data.owner,
        network: data.network,
        chainId: data.chainId,
        contractAddress: data.contractAddress,
        createdAt: data.createdAt,
        creatorId: data.creator,
        currency: data.currency,
        price: data.price,
        royaltyFee: data.royaltyFee,
        description: data.description,
        thumbnail: data.thumbnail,
        asset: {
          size: data.asset.size,
          dimension: data.asset.dimension,
          format: data.asset.format,
        },
        uploadFile: data.uploadFile,
        transId: data.transId,
        views: 0,
        status: data.status,
        isLock: data.isLock,
        isRestricted: data.isRestricted,
        isListed: data.isListed,
      };

      yield put({ type: types.SET_NFT_DETAIL, payload: selectedNft });
    }
  } catch (e) {
    console.error('Error @ Get NFT Detail. ID: ', payload.id);
  }
}

function* banNft({ type, payload }) {
  try {
    const { id } = payload;
    const res = yield axiosApiWithAuth.patch(`/nft/${id}/ban`);

    const { data, success } = res.data;

    if (success) {
      yield put({ type: types.WATCH_NFT_DETAIL, payload: { id } });
      yield put({ type: types.WATCH_NFT_HISTORY, payload: { nftId: id } });
      yield put({ type: types.SET_MODAL_BAN_NFT_SUCCESS, payload });
    }
  } catch (e) {
    console.error('Error @ Banning NFT ID: ', e);
  }
}

function* unbanNft({ type, payload }) {
  try {
    const { id } = payload;
    const res = yield axiosApiWithAuth.patch(`/nft/${id}/unban`);

    const { success } = res.data;

    if (success) {
      yield put({ type: types.WATCH_NFT_DETAIL, payload: { id } });
      yield put({ type: types.WATCH_NFT_HISTORY, payload: { nftId: id } });
      yield put({ type: types.SET_MODAL_UNBAN_NFT_SUCCESS, payload });
    }
  } catch (e) {
    console.error('Error @ Unbanning NFT ID: ', e);
  }
}

function* restrictNft({ type, payload }) {
  try {
    const { id } = payload;
    const res = yield axiosApiWithAuth.patch(`/nft/${id}/delist`);

    const { success } = res.data;

    if (success) {
      yield put({ type: types.WATCH_NFT_DETAIL, payload: { id } });
      yield put({ type: types.SET_MODAL_RESTRICT_NFT_SUCCESS, payload });
    }
  } catch (e) {
    console.error('Error @ Restricting NFT ID: ', e);
  }
}

function* unrestrictNft({ type, payload }) {
  try {
    const { id } = payload;
    const res = yield axiosApiWithAuth.patch(`/nft/${id}/un-delist`);

    const { success } = res.data;

    if (success) {
      yield put({ type: types.WATCH_NFT_DETAIL, payload: { id } });
      yield put({ type: types.SET_MODAL_UNRESTRICT_NFT_SUCCESS, payload });
    }
  } catch (e) {
    console.error('Error @ Unrestricting NFT ID: ', e);
  }
}

export function* nftWatcher() {
  yield takeEvery(types.WATCH_NFT_LIST, nftDataWorker);
  yield takeEvery(types.WATCH_NFT_FILTER, nftFilterWorker);
  yield takeEvery(types.WATCH_NFT_DETAIL, nftDetailWorker);
  yield takeEvery(types.WATCH_BAN_NFT, banNft);
  yield takeEvery(types.WATCH_UNBAN_NFT, unbanNft);
  yield takeEvery(types.WATCH_NFT_HISTORY, nftHistoryWorker);
  yield takeEvery(types.WATCH_RESTRICT_NFT, restrictNft);
  yield takeEvery(types.WATCH_UNRESTRICT_NFT, unrestrictNft);
}
