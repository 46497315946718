import { gql } from '@apollo/client';

/* type Permission {
id: ID!
name: String!
code: String!
modulePrefix: String!
createdAt: DateTime!
updatedAt: DateTime!
deletedAt: DateTime
isIncluded: Boolean
} */

export const CORE_PERMISSION = gql`
  fragment CorePermission on Permission {
    id
    name
    code
    modulePrefix
    createdAt
    updatedAt
    deletedAt
    isIncluded
  }
`;
