import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const AlertBody = ({ title, message }) => {

  const capitalizeTitle = useMemo(() => {
    const _title = title === 'success_infinity' ? 'Success infinity' : title;
    const lower = _title.toLowerCase();

    return _title.charAt(0).toUpperCase() + lower.slice(1);
  }, [title]);

  return (
    <div className="notification__info">
      <div className="notification__title">
        <p>{capitalizeTitle}</p>
      </div>
      <div className="notification__text">
        <p>{message}</p>
      </div>
    </div>
  );
};

AlertBody.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default AlertBody;
