import { put, takeEvery } from 'redux-saga/effects';
import { axiosApiWithAuth } from '../../utils/axiosApi';
import types from '../types';

function* dashboardWorker({ type, payload }) {
  yield put({ type: types.SET_DASHBOARD_DETAIL, payload: { loading: true } });
  try {
    const res = yield axiosApiWithAuth.get(
      `/nft/statistic?query_dashboard=${payload}`,
    );

    const { data, success } = res.data;

    if (success)
      yield put({
        type: types.SET_DASHBOARD_DETAIL,
        payload: { ...data, loading: false },
      });
  } catch (e) {
    console.error('Error @ Fetching Dashboard detail', e);
  }
}

export function* dashboardWatcher() {
  yield takeEvery(types.WATCH_DASHBOARD_DETAIL, dashboardWorker);
}
