import { gql } from '@apollo/client';
import { CORE_PERMISSION } from '../permissions/permissionsFragment';
/* type Role {
  id: ID!
  name: String!
  code: String!
  createdById: String
  updatedById: String
  createdAt: DateTime!
  updatedAt: DateTime!
  deletedAt: DateTime
  modules: [ModuleType!]
  } */
export const CORE_SINGLE_ROLE = gql`
  ${CORE_PERMISSION}
  fragment CoreSingleRole on Role {
    id
    name
    code
    createdById
    updatedById
    createdAt
    updatedAt
    modules {
      name
      permissions {
        ...CorePermission
      }
    }
  }
`;
