import { gql } from '@apollo/client';
import {
  CORE_FEES_RESPONSE_FILEDS,
  CORE_FEES_EDIT_RESPONSE_FIELDS,
} from './feesFragments';

// adminStrategiesList(skip:Int!,limit:Int!,search:String,sort:String,direction:String):adminStrategiesListResponse!
export const FEES = gql`
  ${CORE_FEES_RESPONSE_FILEDS}
  query adminStrategiesList(
    $skip: Int!
    $limit: Int!
    $search: String
    $sort: String
    $direction: String
  ) {
    adminStrategiesList(
      skip: $skip
      limit: $limit
      search: $search
      sort: $sort
      direction: $direction
    ) {
      ...CoreFeesResponseFields
    }
  }
`;

export const QUERY_FEE = gql`
  query fees {
    fees {
      count
      rows {
        id
        name
        code
        amount
        updatedById
        updatedAt
        createdAt
      }
    }
  }
`;

// adminStrategyEdit(strategy:ID!,bridgeFee:String,salesFee:String):adminStrategyEditResponse!
export const UPDATE_FEE = gql`
  ${CORE_FEES_EDIT_RESPONSE_FIELDS}
  mutation adminStrategyEdit(
    $strategy: ID!
    $bridgeFee: String
    $salesFee: String
  ) {
    adminStrategyEdit(
      strategy: $strategy
      bridgeFee: $bridgeFee
      salesFee: $salesFee
    ) {
      ...CoreFeesEditResponseFields
    }
  }
`;
