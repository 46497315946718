/* eslint-disable no-prototype-builtins */
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { encryptor } from '../encryptor';
import types from '../types';

const initialState = {
  loadingList: false,
  filter: {
    count: 0,
    limit: 10,
    page: 1,
    status: '',
    searchValue: '',
  },
  currentList: [],
  selectedNft: {
    _id: '',
    tokenId: '',
    nftName: '',
    artistName: '',
    ownerId: '',
    network: '',
    chainId: undefined,
    contractAddress: '',
    createdAt: '',
    creatorId: '',
    isLock: false,
    currency: '',
    price: 0,
    royaltyFee: 0,
    description: '',
    thumbnail: '',
    asset: {
      size: '',
      dimension: '',
      format: '',
    },
    uploadFile: '',
    transId: '',
    views: 0,
    status: '',
    history: [],
    isRestricted: false,
    isListed: false,
  },
};

const nftReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_NFT_LIST:
      return {
        ...state,
        filter: {
          ...state.filter,
          count: payload.totalCount,
        },
        currentList: payload.currentList,
      };

    case types.SET_NFT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };

    case types.SET_NFT_DETAIL:
      return {
        ...state,
        selectedNft: { ...state.selectedNft, ...payload },
      };

    case types.SET_NFT_HISTORY:
      return {
        ...state,
        selectedNft: {
          ...state.selectedNft,
          history: payload.rows,
        },
      };

    case types.SET_NFT_LOADING:
      return {
        ...state,
        loadingList: payload,
      };

    default:
      return state;
  }
};

const nftConfig = {
  key: 'paybswap_nft_cc',
  storage,
  blacklist: ['filter', 'currentList', 'selectedNft'],
  transforms: [encryptor],
};

export default persistReducer(nftConfig, nftReducer);
