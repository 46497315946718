import './styles/css/admin.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import 'react-toastify/dist/ReactToastify.css';
import { Provider, useDispatch } from 'react-redux';

import { BrowserRouter, useHistory } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from './styles/themeProvider';
import { persistor, store } from './redux/store';
import { client } from './apollo/client';
import App from './components/app/index';
import './styles/fonts/Roboto/index.css';

import './i18n';

export const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="wrapper">
      <div className="content">
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </div>
    </div>
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading="Loader" persistor={persistor}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
