export const saveSession = data => {
  const now = new Date();
  // 1000ms * 60sec * 60min * 24h * ?days
  const expirationTime = 1000 * 60 * 60 * 24;
  // `session` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const session = {
    token: data.token,
    user: data.user,
    expiry: now.getTime() + expirationTime,
  };
  localStorage.setItem('session', JSON.stringify(session));
};

export const checkSession = eraseMemory => {
  const session = localStorage.getItem('session');

  // if the session doesn't exist, return null
  if (!session) {
    return null;
  }

  const data = JSON.parse(session);
  const now = new Date();

  // compare the expiry time of the data with the current time
  if (now.getTime() > data.expiry) {
    // If the data is expired, delete the data from storage
    // and return null
    eraseMemory();
    localStorage.removeItem('session');
    return null;
  }
  return data;
};
