import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { client } from '../../apollo/client';
import { dropIn } from '../../motionVariants/modalVariants';
// redux
import types from '../../redux/types';
// components
import Backdrop from './backdrop';
import Spinner from '../widgets/spinner/spinner';
// icons
import CloseIcon from '../widgets/icons/modals/closeIcon';
import SelectIcon from '../widgets/icons/common/selectIcon';

const UserEdit = props => {
  const dispatch = useDispatch();
  const roles = useSelector(state => state?.roles?.data);
  const {
    id: userId,
    status,
    processing,
    title,
    role,
  } = useSelector(state => state.modals.userEdit);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ id: role.id, name: role.name });

  const handleClose = useCallback(
    e => {
      dispatch({ type: types.WATCH_REM_MODAL, payload: { type: 'userEdit' } });
    },
    [dispatch],
  );

  const handleSubmit = useCallback(async () => {
    try {
      console.log('wot');
    } catch (err) {
      return err;
    }
  }, [userId, selected]);

  const handleSelect = useCallback(
    (id, name) => {
      setSelected({ id, name });
      setOpen(!open);
    },
    [open],
  );

  const renderSelectOptions = useCallback(() => {
    return roles.map(({ id, name }) => {
      return (
        <li key={id}>
          <button type="button" onClick={e => handleSelect(id, name)}>
            {name}
          </button>
        </li>
      );
    });
  }, [roles, handleSelect]);

  return (
    <Backdrop onClick={processing ? null : handleClose}>
      <motion.div
        className="popup-window__inside"
        onClick={e => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {processing && <Spinner />}

        <div className="popup">
          <button type="button" className="popup__close" onClick={handleClose}>
            <div className="popup__close_icon">
              <CloseIcon />
            </div>
          </button>
          <div className="popup-header">
            <p className="popup-header__title">{title}</p>
          </div>
          <div className="popup-body">
            <div className="select-block">
              <p className="select-block__name">Role</p>
              <div className={classnames('select', { active: open })}>
                <button
                  className="select__current"
                  type="button"
                  onClick={e => setOpen(!open)}
                >
                  {selected.name}
                  <SelectIcon />
                </button>
                <div className="select__drop">
                  <div className="select__drop-scroll">
                    <div className="select__drop-item">
                      <ul>{renderSelectOptions()}</ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="button button--full-width"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

UserEdit.propTypes = {};

export default UserEdit;
