import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* strategiesDataWorker({ type, payload }) {
  yield put({ type: types.SET_STRATEGIES_DATA, payload });
}

function* strategiesFilterWorker({ type, payload }) {
  yield put({ type: types.SET_STRATEGIES_FILTER, payload });
}

export function* strategiesWatcher() {
  yield takeEvery(types.WATCH_STRATEGIES_DATA, strategiesDataWorker);
  yield takeEvery(types.WATCH_STRATEGIES_FILTER, strategiesFilterWorker);
}
