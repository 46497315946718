import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { dropIn } from '../../motionVariants/modalVariants';
// redux
import types from '../../redux/types';
// components
import Backdrop from './backdrop';
import Spinner from '../widgets/spinner/spinner';
import CloseIcon from '../widgets/icons/modals/closeIcon';
import { ADMIN_TRANSFER_NFT, NFT_HOLD } from '../../queries/nft/nftQueries';

const NftFee = () => {
  const dispatch = useDispatch();
  const {
    processing,
    title,
    id,
  } = useSelector(state => state.modals.nftFee);

  const [enabledBtn, setEnabledBtn] = useState(true);
  const [address, setAddress] = useState('');

  const handleClose = useCallback(
    e => {
      dispatch({ type: types.WATCH_REM_MODAL, payload: { type: 'nftFee' } });
    },
    [dispatch],
  );

  const [nftHold] = useMutation(NFT_HOLD, {
    onError: err => {
      setEnabledBtn(true);

      dispatch({
        type: types.WATCH_SET_ALERT,
        payload: { msg: err?.message || err, type: 'fail' },
      });
    },
    onCompleted: data => {
      dispatch({
        type: types.UPDATE_CAN_TRASFER_NFT,
        payload: {
          id,
        }
      })
      handleClose();
      setEnabledBtn(false);
    },
  });

  const [mutationTransferNft, { loading, error }] = useMutation(ADMIN_TRANSFER_NFT, {
    onCompleted: data => {
      const variables = {
        id: +id,
      };

      dispatch({
        type: types.WATCH_SET_ALERT,
        payload: { msg: `Hash: ${data.adminTransferNft.hash}`, type: 'success_infinity' },
      });

      nftHold({ variables });
    },
    onError: err => {
      setEnabledBtn(true);

      dispatch({
        type: types.WATCH_SET_ALERT,
        payload: { msg: err?.message || err, type: 'fail' },
      });
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const sendToken = useCallback(() => {
    if (address) {
      const variables = {
        nft: id,
        address,
      };

      setEnabledBtn(false);

      mutationTransferNft({ variables });
    } else {
      dispatch({
        type: types.WATCH_SET_ALERT,
        payload: { msg: 'Address: must not be an empty string.', type: 'fail' },
      });
    }
  // eslint-disable-next-line
  }, [id, address, mutationTransferNft, nftHold]);

  return (
    <Backdrop onClick={processing ? null : handleClose}>
      <motion.div
        className="popup-window__inside"
        onClick={e => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {processing && <Spinner />}

        <div className="popup">
          <button type="button" className="popup__close" onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className="popup-header">
            <p className="popup-header__title">{title}</p>
          </div>
          <div className="popup-body">
            <div className="input">
              <div className="input-wrapper">
                <p className="input__name">Address</p>
                <input className="input-item" type="text" placeholder="Address" value={address} onChange={e => setAddress(e.currentTarget.value)} />
              </div>
            </div>
            <button type="button" disabled={!enabledBtn} className="button button--full-width" onClick={sendToken}>
              Send
            </button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

NftFee.propTypes = {};

export default NftFee;
