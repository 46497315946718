import { put, takeEvery } from 'redux-saga/effects';
import types from '../types';

function* usersDataWorker({ type, payload }) {
  yield put({ type: types.SET_USERS_DATA, payload });
}

function* usersFilterWorker({ type, payload }) {
  yield put({ type: types.SET_USERS_FILTER, payload });
}

export function* usersWatcher() {
  yield takeEvery(types.WATCH_USERS_DATA, usersDataWorker);
  yield takeEvery(types.WATCH_USERS_FILTER, usersFilterWorker);
}
