import { put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import types from '../types';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* setAlertWorker({ type, payload }) {
  const id = uuidv4();
  yield put({ type: types.SET_ALERT, payload: { ...payload, id } });

  if (payload.type !== 'success_infinity') {
    yield delay(payload.type === 'fail' ? 6000 : 3000);
    yield put({ type: types.WATCH_REM_ALERT, payload: id });
  }
}

function* remAlertWorker({ type, payload }) {
  yield put({ type: types.REM_ALERT, payload });
}

export function* notificationWatcher() {
  yield takeEvery(types.WATCH_SET_ALERT, setAlertWorker);
  yield takeEvery(types.WATCH_REM_ALERT, remAlertWorker);
}
