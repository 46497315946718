import { gql } from '@apollo/client';

import {
  CORE_NFT_LIST_RESULT_FIELDS,
} from './nftFragments';

export const NFT_HOLD = gql`
  mutation nftHold($id: ID!) {
    nftHold(id: $id) {
      status
    }
  }
`;

export const ADMIN_TRANSFER_NFT = gql`
  mutation adminTransferNft(
    $nft: String!,
    $address: String!
  ) {
    adminTransferNft(
      nft: $nft
      address: $address
    ) {
      hash
    }
  }
`;

export const SET_PATFORM_SETTINGS = gql`
  mutation setPlatformSetting(
    $name: String!,
    $value: String!
  ) {
    setPlatformSetting(
      name: $name
      value: $value
    ) {
      value
    }
  }
`;

export const GET_PLATFORM_SETTINGS = gql`
  query getPlatformSetting(
    $name: String!
  ) {
    getPlatformSetting(
      name: $name
    ) {
      name
      value
    }
  }
`;

export const GET_NFT_LIST = gql`
  ${CORE_NFT_LIST_RESULT_FIELDS}
  query adminNftList(
    $skip: Int!
    $limit: Int!
    $status: String
    $min: Float
    $max: Float
    $network: String
    $chain: String
    $sortBy: String
    $owner_id: Int
    $creatorAddress: String
    $ownerAddress: String
    $search: String
  ) {
    adminNftList(
      skip: $skip
      limit: $limit
      status: $status
      min: $min
      max: $max
      network: $network
      chain: $chain
      sortBy: $sortBy
      owner_id: $owner_id
      creatorAddress: $creatorAddress
      ownerAddress: $ownerAddress
      search: $search
    ) {
      ...CoreNFTListFields
    }
  }
`;

export const GET_NFT_FEE = gql`
  query adminGetNftPlatformFee {
    adminGetNftPlatformFee {
      bscFee
      ethFee
    }
  }
`;

export const SET_NFT_FEE = gql`
  mutation adminSetNftPlatformFee($fee: String!, $network: String!) {
    adminSetNftPlatformFee(fee: $fee, network: $network) {
      status
      hash
    }
  }
`;
