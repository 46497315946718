/* eslint-disable no-prototype-builtins */

import types from '../types';

const initialState = {
  loader: false,
  filter: {
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
    criteria: '',
  },
  filterConditions: [
    {
      key: 'latest',
      title: 'Latest'
    },
    {
      key: 'coming_soon',
      title: 'Coming soon'
    },
    {
      key: 'finished',
      title: 'Finished'
    },
  ],
  data: [],
  projektListCount: 0
};

const nftLpReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_NFT_FILTER:
      return {
        ...state,
        filter: {
          ...payload.filter,
          ...payload,
        },
      };
    case types.SET_NFT_LP_DATA:
      return {
        ...state,
        data: payload.rows,
        filter: { ...state.filter, count: payload.count }
      };
    case types.SET_NFT_LP_FILTER_START:
      return {
        ...state,
        filter: {
          ...state.filter,
          count: initialState.filter.count,
          limit: initialState.filter.limit,
          skip: initialState.filter.skip,
          page: initialState.filter.page,
        },
      };

    case types.SET_NFT_LP_FILTER:
      return {
        ...state,
        filter: {
          ...payload.filter,
          ...payload,
        },
      };
    default:
      return state;
  }
};



export default nftLpReducer;
