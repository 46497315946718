import React, { Suspense, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { GlobalStyles } from '../../styles/globalStyles';
// redux
import types from '../../redux/types';
// routes
import routes from '../../routes';
import { QUERY_SINGLE_ROLE } from '../../queries/roles/rolesQueries';
import { QUERY_ME } from '../../queries/auth/queries';
// services
import { checkSession } from '../../services/rememberMe';
// components
import Sidebar from '../dashboard/sidebar/sidebar';
import HeaderContentWrapper from '../dashboard/header/headerContentWrapper';
import AlertsWrapper from '../notifications/alertsWrapper';
import Spinner from '../widgets/spinner/spinner';
// modals
import Backdrop from '../modals/backdrop';
import NftFee from '../modals/nftSendTo';
import UserEdit from '../modals/userEdit';
import FeesEdit from '../modals/feesEdit';

const styleCorrection = {
  width: '100%',
  overflow: 'hidden',
};

const AppContainer = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const lightTheme = useSelector(state => state.options.themes.light);
  const auth = useSelector(state => state?.auth);
  const accessToken = localStorage.getItem('accessToken') || auth.token;

  const userStatus = useMemo(() => {
    if (location.pathname.includes('/auth/confirm-email')) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('resetToken');
      dispatch({
        type: types.RESET_AUTH,
      });
      return 'confirm-email';
    }

    if (location.pathname.includes('/auth/change-email-password')) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('resetToken');
      dispatch({
        type: types.RESET_AUTH,
      });
      return 'change-password';
    }

    if (accessToken) return 'superadmin';
  }, [accessToken, dispatch, location.pathname]);

  const eraseMemory = useCallback(() => {
    dispatch({ type: types.WATCH_TOKEN, payload: { token: '' } });
    dispatch({ type: types.WATCH_USER, payload: undefined });
  }, [dispatch]);

  const [getMe, { loading: loadingMe, data: dataMe, error: errorMe }] =
    useLazyQuery(QUERY_ME);

  const [getRole, { loading: loadingRole, data: dataRole, error: errorRole }] =
    useLazyQuery(QUERY_SINGLE_ROLE, {
      variables: {
        id: '',
      },
    });

  useEffect(() => {
    if (accessToken !== '' || accessToken) {
      getMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (dataMe?.me) {
      getRole({
        variables: {
          id: dataMe.me.roleObj.id,
        },
      });
      dispatch({
        type: types.SET_AUTH_DETAIL,
        payload: {
          ...auth,
          email: dataMe.me.primaryEmailCredential.email || 'N/A',
          fullName: dataMe.me.profile.nickname || 'N/A',
          roleName: dataMe.me.roleObj.name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMe]);

  useEffect(() => {
    if (dataRole?.role) {
      dispatch({
        type: types.SET_AUTH_DETAIL,
        payload: {
          ...auth,
          permissions: dataRole.role,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRole]);

  const handleLoginFromStorage = useCallback(() => {
    const data = checkSession(eraseMemory);

    if (data) {
      dispatch({
        type: types.WATCH_USER,
        payload: data.user,
      });

      dispatch({
        type: types.WATCH_TOKEN,
        payload: {
          token: data.token,
        },
      });
    }
  }, [dispatch, eraseMemory]);

  useEffect(() => {
    handleLoginFromStorage();
  }, [handleLoginFromStorage]);

  return <App lightTheme={lightTheme} userStatus={userStatus} />;
};

const App = ({ lightTheme, userStatus }) => {
  const location = useLocation();

  // modals status && modals process
  const { status: nftFeeStatus, processing: nftFeeProcess } = useSelector(
    state => state.modals.nftFee,
  );

  const { status: userEditStatus, processing: userEditProcess } = useSelector(
    state => state.modals.userEdit,
  );

  const { status: feeEditStatus, processing: feeEditProcess } = useSelector(
    state => state.modals.feeEdit,
  );

  if (userStatus === 'superadmin' || userStatus === 'approve') {
    return (
      <main
        className={classNames(
          'main',
          { 'white-bg': lightTheme },
          { 'black-bg': !lightTheme },
        )}
      >
        {/* alerts */}
        <AlertsWrapper />
        {/* modals */}
        <AnimatePresence initial={false} exitBeforeEnter>
          {nftFeeStatus && <NftFee />}
          {userEditStatus && <UserEdit />}
          {feeEditStatus && <FeesEdit />}

          {(nftFeeProcess || userEditProcess || feeEditProcess) && (
            <Backdrop>
              <Spinner />
            </Backdrop>
          )}
        </AnimatePresence>
        {/* main */}
        <section className="main-section">
          <Sidebar />
          <div className="main-content" style={styleCorrection}>
            <HeaderContentWrapper />
            <Suspense fallback={<Spinner />}>
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                  <Route
                    exact
                    path={routes.Dashboard.path}
                    component={routes.Dashboard.component}
                  />
                  <Route
                    exact
                    path={routes.Fees.path}
                    component={routes.Fees.component}
                  />
                  <Route
                    exact
                    path={routes.Users.path}
                    component={routes.Users.component}
                  />
                  <Route
                    exact
                    path={routes.Nft.path}
                    component={routes.Nft.component}
                  />
                  <Route
                    path={routes.NftLaunchpad.path}
                    component={routes.NftLaunchpad.component}
                  />
                  <Route
                    exact
                    path={routes.NftDetail.path}
                    component={routes.NftDetail.component}
                  />
                  <Route
                    exact
                    path={routes.Mods.path}
                    component={routes.Mods.component}
                  />
                  <Route
                    path={routes.Settings.path}
                    component={routes.Settings.component}
                  />
                  <Route
                    exact
                    path={routes.Profile.path}
                    component={routes.Profile.component}
                  />
                  <Route key="404">
                    <Redirect to={routes.Dashboard.path} />
                  </Route>
                  <Redirect to={routes.Dashboard.path} />
                </Switch>
              </AnimatePresence>
            </Suspense>

            <ToastContainer />
          </div>
        </section>
      </main>
    );
  }
  return (
    <main
      className={classNames(
        'main',
        { 'white-bg': lightTheme },
        { 'black-bg': !lightTheme },
      )}
    >
      <AlertsWrapper />
      <Suspense fallback={<Spinner />}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            <Route
              exact
              path={routes.Signin.path}
              component={routes.Signin.component}
            />
            <Route
              exact
              path={routes.ConfirmEmail.path}
              component={routes.Signin.component}
            />
            <Route
              exact
              path={routes.ChangePassword.path}
              component={routes.Signin.component}
            />
            <Route
              exact
              path={routes.Signup.path}
              component={routes.Signup.component}
            />
            <Redirect to={routes.Signin.path} />
          </Switch>
        </AnimatePresence>
      </Suspense>

      <GlobalStyles />

      <ToastContainer />
    </main>
  );
};

App.defaultProps = {
  userStatus: '',
};

App.propTypes = {
  lightTheme: PropTypes.bool.isRequired,
  userStatus: PropTypes.string,
};

export default React.memo(AppContainer);
