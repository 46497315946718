import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { user, logoutIcon } from '../../../assets/icons';
import types from '../../../redux/types';

const HeaderContentWrapper = props => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const dropdownFilter = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector(store => store.auth);

  const title = useMemo(() => {
    let name;
    if (pathname.includes('/stats')) {
      name = 'Dashboard';
    } else if (pathname.includes('nft')) {
      name = 'NFT';
    } else if (pathname.includes('/fee')) {
      name = 'Fee';
    } else if (pathname.includes('/user')) {
      name = 'Users';
    } else if (pathname.includes('/mods')) {
      name = 'Mods';
    }
    return name;
  }, [pathname]);

  useEffect(() => {
    const checkIfCloseOutside = e => {
      if (
        show &&
        dropdownFilter.current &&
        !dropdownFilter.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener('click', checkIfCloseOutside);
    return () => {
      document.removeEventListener('click', checkIfCloseOutside);
    };
  });

  const goToProfile = () => {
    history.push('/dashboard/profile');
  };

  const handleLogout = useCallback(async () => {
    //      const res = await axiosApiWithAuth.post('/auth/logout');

    // console.log(res)

    dispatch({
      type: types.RESET_AUTH,
    });

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

  }, [dispatch]);

  return (
    <header className="header">
      <DivHeaderMain>
        <DivNFTMain>
          <SpanNameTitle>{title}</SpanNameTitle>
        </DivNFTMain>
        <DivUserImage onClick={() => setShow(!show)}>
          {/* <div className="adminName">{auth.fullName}</div> */}
          <div className="adminCredential">
            <p className="adminName">{auth.fullName}</p>
            <p className="adminRole">{auth.roleName}</p>
          </div>
          <div className="adminAva">
            <ImgUser src={user} />
          </div>
        </DivUserImage>
        {show && (
          <ProfileDropdown ref={dropdownFilter}>
            <div className="email">{auth.email}</div>
            <div className="goTo" onClick={goToProfile}>Edit Profile</div>
            <div className="goTo">My Permissions</div>
            <hr style={{ width: '100%' }} />
            <div className="logOut" onClick={() => handleLogout()}>
              <img src={logoutIcon} alt="logout" />
              Logout
            </div>
          </ProfileDropdown>
        )}
      </DivHeaderMain>
    </header>
  );
};

const DivPopup = styled.div`
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999999;
  top: 8%;
  width: 278px;
  display: none;
  background: #fff;
  div:first-child {
    border-radius: 10px 10px 0px 0px;
  }
  div:last-child {
    border-radius: 0px 0px 10px 10px;
  }
  div:nth-child(2) {
    border-radius: 0px;
  }

  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &.show {
    visibility: visible;
    ${'' /* opacity: 1; */}
    display: flex;
  }
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 5rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: 1px solid #ababab;
  border-radius: 8px;
  padding: 1.5rem;
  background: #fff;
  z-index: 9999;

  div {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .email {
    color: ${p => p.theme.primary};
    margin-bottom: 0.5rem;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }

  .logOut {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${p => p.theme.error};
  }
`;

const DivHeaderMain = styled.div`
  display: flex;
  flex-direction: rows;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DivNFTMain = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
`;
const SpanNameTitle = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  margin-right: 10px;
  color: #232323;
`;

const SpanNameSubTitle = styled.span`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 34px;
  margin-left: 10px;
  /* identical to box height */

  color: #232323;
`;

const ImgPopup = styled.img`
  width: 20px;
  height: 12px;
`;

const DivUserImage = styled.div`
  //width: 44px;
  height: 44px;

  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  .adminCredential {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .adminName {
    font-size: 1.15rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
  }

  .adminRole { 
    color: ${p => p.theme.textSecondary};
    font-size: 0.875rem;
  }
`;

const ImgUser = styled.img`
  width: 100%;
  height: 100%;
`;

HeaderContentWrapper.propTypes = {};

export default HeaderContentWrapper;
