export const dev = () => {
  return (
    process.env.REACT_APP_ENVIRONMENT !== 'prod' ||
    process.env.NODE_ENV === 'development'
  );
};

export const API_URL = dev()
  ? process.env.REACT_APP_API_TEST_URI
  : process.env.REACT_APP_API_URI;

export const NFT_CHAIN_NETWORK = {
    POLYGON: {
      NAME: 'Polygon',
      ID: process.env.REACT_APP_MATIC_NETWORK_CHANNEL_ID,
      SYMBOL: 'MATIC',
      PROVIDER: process.env.REACT_APP_MATIC_NETWORK_PROVIDER
    },
    ETHEREUM: {
      NAME: 'Ethereum',
      ID: process.env.REACT_APP_ETH_NETWORK_CHANNEL_ID,
      SYMBOL: 'ETH',
      PROVIDER: process.env.REACT_APP_ETH_NETWORK_PROVIDER
    },
    BSC: {
      NAME: 'Binance Smart Chain',
      ID: process.env.REACT_APP_BSC_NETWORK_CHANNEL_ID,
      SYMBOL: 'BNB',
      PROVIDER: process.env.REACT_APP_BSC_NETWORK_PROVIDER
    },
    AVALANCHE: {
      NAME: 'Avalanche',
      ID: process.env.REACT_APP_AVAX_NETWORK_CHANNEL_ID,
      SYMBOL: 'AVAX',
      PROVIDER: process.env.REACT_APP_AVALANCHE_NETWORK_PROVIDER
    },
    MOONBEAM: {
      NAME: 'Moonbeam',
      ID: process.env.REACT_APP_MOONBEAM_NETWORK_CHANNEL_ID,
      SYMBOL: 'GLMR',
      PROVIDER: process.env.REACT_APP_MOONBEAM_NETWORK_PROVIDER
    },
  }
export const ADDRESS_CONFIG = {
  ADDRESS_ZERO: '0x0000000000000000000000000000000000000000',
};
